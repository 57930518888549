import React from 'react'
import { Typography, Grid } from '@mui/material'
import dayjs from 'dayjs'

const Ec2Details = ({ componentData }: any) => {
  return (
    <Grid container flexDirection="row">
      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Image Id:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {componentData.details.imageId}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Architecture:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {componentData.details.imageArchitecture}
          </Typography>
        </Grid>
      </Grid>

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Creation Time:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {dayjs(componentData.details.imageCreationDate).format('MMM D, YYYY, h:mm A')}
          </Typography>
        </Grid>
      </Grid>

      {componentData.details.imageDeprecationTime && (
        <Grid container item xs={6}>
          <Grid item xs={3}>
            <Typography variant="body2" gutterBottom>
              Deprecation Date:
            </Typography>
          </Grid>

          <Grid item xs={9}>
            <Typography variant="body2" gutterBottom>
              {dayjs(componentData.details.imageDeprecationTime).format('MMM D, YYYY, h:mm A')}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Platform:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {componentData.details.imagePlatformDetails}
          </Typography>
        </Grid>
      </Grid>

      {componentData.details.instanceLifeCycle && (
        <Grid container item xs={6}>
          <Grid item xs={3}>
            <Typography variant="body2" gutterBottom>
              Instance Lifecycle:
            </Typography>
          </Grid>

          <Grid item xs={9}>
            <Typography variant="body2" gutterBottom>
              {componentData.details.instanceLifeCycle}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container item xs={6}>
        <Grid item xs={3}>
          <Typography variant="body2" gutterBottom>
            Instance Type:
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography variant="body2" gutterBottom>
            {componentData.details.instanceType}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Ec2Details
