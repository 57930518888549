import { jwtDecode } from 'jwt-decode'
import { get } from 'lodash'

export type ObjectValues<T> = T[keyof T]

export const isInternalEmployee = (userEmail: string | undefined, sessionToken: string) => {
  const decodedToken = jwtDecode(sessionToken)
  const isImpersonation = get(decodedToken, 'act.sub') ? true : false
  return (userEmail && userEmail.endsWith('@draftt.io')) || isImpersonation
}
